export default {
  safetyFeatureCategories: {
    isLoading: false,
    hasError: false,
    data: null,
  },
  ancapTestCategories: {
    isLoading: false,
    hasError: false,
    data: null,
  },
};
