import React from 'react';

const FilterIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12" {...props}>
    <g fill="none" fillRule="evenodd" transform="translate(0 1)">
      <rect width="20" height="2" y="1" fill="#1E1E1E" rx="1" />
      <rect width="20" height="2" y="7" fill="#1E1E1E" rx="1" />
      <circle cx="13" cy="2" r="2" fill="#FFF" stroke="#1E1E1E" strokeWidth="2" />
      <circle cx="7" cy="8" r="2" fill="#FFF" stroke="#1E1E1E" strokeWidth="2" />
    </g>
  </svg>
);

export default FilterIcon;
