import React, { useMemo } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import _ from 'lodash';

import initializeStore from '../app-state/store';

import extractSeoData from './seo/utils/extract-seo-data';
import fetchSeoData from './seo/utils/fetch-seo-data';
import hydrateFiltersStateFromUrl from './hydrate-filters-state-from-url';
import { hydrateVehicleIdsStateFromUrl } from './hydrate-vehicle-ids-state';
import { redirectToLowercaseUrl } from './redirect-to-lowercase-url';
import SeoContext from './seo/context';

const checkServer = () => {
  return typeof window === 'undefined';
};

const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

const getOrCreateStore = (initialState) => {
  // Always make a new store if server, otherwise state is shared between requests
  if (checkServer()) {
    return initializeStore(initialState);
  }

  // Create store if unavailable on the client and set it on the window object
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = initializeStore(initialState);
  }
  return window[__NEXT_REDUX_STORE__];
};

export const withAppProps = (getPageProps) => {
  return async (ctx) => {
    // Get or Create the store with `undefined` as initialState
    // This allows you to set a custom default initialState
    const reduxStore = getOrCreateStore();

    let redirect = null;

    if (_.includes(ctx.resolvedUrl, 'results')) {
      hydrateFiltersStateFromUrl(ctx.query, reduxStore.dispatch.filters);
    }

    if (ctx.query?.vehicles) {
      hydrateVehicleIdsStateFromUrl(ctx.query, reduxStore.dispatch.compare);
    }

    redirect = redirectToLowercaseUrl(ctx?.resolvedUrl, ctx?.res);

    const seoData = await fetchSeoData(ctx?.resolvedUrl);
    const seoProps = extractSeoData(seoData);

    const pageData = (await getPageProps?.(ctx)) ?? { props: {} };

    if (redirect) {
      return {
        redirect: { destination: redirect, permanent: true },
      };
    }

    const { res } = ctx;
    if (res && !res._headerSent && !res.getHeader('cache-control')) {
      // If no cache headers set, cache responses for 30 seconds at the CDN level serve cached version whilst revalidating
      // https://zeit.co/blog/serverless-pre-rendering
      res.setHeader('cache-control', 's-maxage=30, stale-while-revalidate=86400, stale-if-error=86400');
    }

    return {
      ...pageData,
      props: {
        ...pageData.props,
        initialReduxState: reduxStore.getState(),
        seoProps,
      },
    };
  };
};

export const withAppProviders = (Component) => (props) => {
  const { seoProps, initialReduxState, ...pageProps } = props;
  const reduxStore = useMemo(() => getOrCreateStore(initialReduxState));

  return (
    <ReduxProvider store={reduxStore}>
      <SeoContext.Provider value={seoProps}>
        <Component {...pageProps} />
      </SeoContext.Provider>
    </ReduxProvider>
  );
};
