import React from 'react';
import styled from 'styled-components';
import { isNumber } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { spacing as muiSpacing } from '@material-ui/system';

import { GRID, BREAKPOINTS } from '../../constants';

const getGutterStyles = (offset) => `
  padding-left: ${GRID.GUTTER_HALF};
  padding-right: ${GRID.GUTTER_HALF};

  &.MuiGrid-item {
    margin-left: ${offset ? offset[0] : 0};

    @media (min-width: ${BREAKPOINTS.SM}px) {
      margin-left: ${offset[1]};
    }

    @media (min-width: ${BREAKPOINTS.MD}px) {
      margin-left: ${offset[2]};
    }

    @media (min-width: ${BREAKPOINTS.LG}px) {
      margin-left: ${offset[3]};
    }

    @media (min-width: ${BREAKPOINTS.XL}px) {
      margin-left: ${offset[4]};
    }
  `;

const StyledCol = styled(Grid)`
  ${({ spacing, offset }) => !isNumber(spacing) && getGutterStyles(offset)};

  ${muiSpacing}
`;

const toPercent = (v) => `${v * 100}%`;

const Col = ({ className, offset, width, ...props }) => (
  <StyledCol {...props} {...width} className={className} item offset={offset ? offset.map(toPercent) : []} />
);

export default Col;
