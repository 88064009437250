import { includes, inRange, map, values } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Image from 'next/image';

import Icon, { iconNames } from '../icons';
import { RATERS, COLORS } from '../../constants';
import { ExtraSmall } from '../type';
import Flex from '../grid/flex';
import AncapLogo from '../../public/images/ancap-logo.svg';
import UcsrLogo from '../../public/images/ucsr-logo.svg';

import {
  LogoImageWrapper,
  StarRatingWrapper,
  StarsWrapper,
  Stars,
  RatingLabelWrapper,
  AncapTestYearLabel,
  AncapTestYear,
  OverallSafetyLabel,
} from './styles';
import { STAR_PLACEMENT } from './constants';

const LOGO_IMAGE_BY_RATER = {
  [RATERS.ANCAP]: {
    src: AncapLogo,
    alt: 'rated by ANCAP',
  },
  [RATERS.UCSR]: {
    src: UcsrLogo,
    alt: 'rated by UCSR',
  },
};

const StarRating = ({ stars, ratingBy, placement, className, forceZeroStars, testYear }) => {
  const noProvider = [RATERS.NO_RATING, RATERS.NO_CURRENT_RATING].includes(ratingBy);
  const validStars = inRange(stars, 1, 6);
  const starsArray = [];
  for (let i = 1; i <= 5; i++) {
    if (validStars) {
      if (stars >= i) {
        starsArray.push(iconNames.star);
      } else {
        starsArray.push(iconNames.starDefault);
      }
    } else {
      starsArray.push(iconNames.starOutline);
    }
  }

  const isNotRated = includes([RATERS.NO_CURRENT_RATING, RATERS.NO_RATING], ratingBy);

  const showRatingProviderLogo =
    ratingBy && !noProvider && (validStars || forceZeroStars) && placement !== STAR_PLACEMENT.SAFETY_ROW;

  return (
    <Flex
      flexDirection="column"
      alignItems={isNotRated || placement === STAR_PLACEMENT.SAFETY_ROW ? 'flex-start' : 'center'}
      maxWidth="100%"
      role="img"
      aria-label={`${ratingBy} - ${stars} out of 5 stars`}
      tabIndex="0"
      className={className}
    >
      {showRatingProviderLogo && (
        <LogoImageWrapper ratingBy={ratingBy}>
          <Image
            src={LOGO_IMAGE_BY_RATER[ratingBy].src}
            alt={LOGO_IMAGE_BY_RATER[ratingBy].alt}
            ratingBy={ratingBy}
            placement={placement}
          />
        </LogoImageWrapper>
      )}
      <StarRatingWrapper ratingBy={ratingBy}>
        {!includes(
          [STAR_PLACEMENT.COMPARISON_TILE, STAR_PLACEMENT.COMPARISON_CARD, STAR_PLACEMENT.SAFETY_ROW],
          placement
        ) && <RatingByProvider ratingBy={ratingBy} testYear={testYear} />}
        {stars === 0 && forceZeroStars ? (
          <ExtraSmall as="span">ZERO STARS</ExtraSmall>
        ) : (
          <StarsWrapper
            showBorder={ratingBy === RATERS.ANCAP}
            noPadding={isNotRated || placement === STAR_PLACEMENT.SAFETY_ROW}
            ratingBy={ratingBy}
            isNotRated={isNotRated}
            placement={placement}
          >
            {map(starsArray, (star, index) => (
              <Stars isNotRated={isNotRated} placement={placement} key={index}>
                <StyledIcon ratingBy={ratingBy} name={star} />
              </Stars>
            ))}
          </StarsWrapper>
        )}
      </StarRatingWrapper>
    </Flex>
  );
};

const StyledIcon = styled(Icon)`
  color: ${({ ratingBy }) => (ratingBy === RATERS.UCSR ? COLORS.BRAND_BLUE_TWELVE : COLORS.BLACK)};
`;

const RatingByProvider = ({ ratingBy, testYear }) => {
  if (ratingBy === RATERS.ANCAP && testYear) {
    return (
      <RatingLabelWrapper ratingBy={ratingBy}>
        <AncapTestYearLabel>Tested</AncapTestYearLabel>
        <AncapTestYear>{testYear}</AncapTestYear>
      </RatingLabelWrapper>
    );
  }
  if (ratingBy === RATERS.UCSR) {
    return (
      <RatingLabelWrapper ratingBy={ratingBy}>
        <OverallSafetyLabel>Overall Safety</OverallSafetyLabel>
      </RatingLabelWrapper>
    );
  }
  return null;
};

StarRating.propTypes = {
  placement: PropTypes.oneOf(values(STAR_PLACEMENT)).isRequired,
  ratingBy: PropTypes.oneOf(values(RATERS)),
  stars: PropTypes.number,
  className: PropTypes.string,
  forceZeroStars: PropTypes.bool,
  testYear: PropTypes.number,
};

StarRating.defaultProps = {
  ratingBy: null,
  stars: null,
  className: null,
  forceZeroStars: false,
  testYear: undefined,
};

export default StarRating;
