import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import { COLORS, BOX_SHADOW } from '../../constants';

const expansionPanelBorderRadius = { borderRadius: 0 };

const clearSummaryPanelStyles = {
  padding: 0,
  margin: 0,
};

const StyledAccordionFaq = withStyles({
  root: {
    boxShadow: BOX_SHADOW.DEFAULT,
    '&:hover': { boxShadow: BOX_SHADOW.HOVER },
  },
  rounded: {
    '&:last-child': expansionPanelBorderRadius,
  },
})(Accordion);

const StyledAccordion = withStyles({
  root: {
    '&:before': { opacity: 0 },
    boxShadow: 'none',
    backgroundColor: COLORS.GREY_ONE,
    '&.Mui-expanded': { ...clearSummaryPanelStyles },
  },
})(Accordion);

const styledAccordionSummaryStyles = {
  root: {
    ...clearSummaryPanelStyles,
    '&.Mui-focused': {
      boxShadow: `inset 0 0 0 2px ${COLORS.BRAND_BLUE_SIX}`,
      borderRadius: 1,
    },
    '&:hover': {
      backgroundColor: COLORS.GREY_FOUR,
      boxShadow: `inset 0 0 0 2px ${COLORS.BRAND_BLUE_SIX}`,
      borderRadius: 1,
    },
    '&.Mui-expanded': { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
  },
  content: { ...clearSummaryPanelStyles, '&.Mui-expanded': { ...clearSummaryPanelStyles } },
  expandIcon: { color: COLORS.GREY_ELEVEN },
};

const StyledAccordionSummaryFaq = withStyles({
  root: {
    ...styledAccordionSummaryStyles.root,
    ...expansionPanelBorderRadius,
  },
  content: styledAccordionSummaryStyles.content,
  expandIcon: { color: COLORS.GREY_ELEVEN },
})(AccordionSummary);

const StyledAccordionSummary = withStyles(styledAccordionSummaryStyles)(AccordionSummary);

const StyledAccordionDetails = withStyles({
  root: { padding: 0 },
})(AccordionDetails);

const expandIconMargin = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: '24px',
    },
    paddingRight: '48px',
  },
}));

const expandIconSize = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '36px',
    },
    fontSize: '44px',
  },
}));

const DividerFaq = styled.div`
  height: 3px;
  background-color: ${COLORS.GREY_THREE};
`;

const Divider = styled.div`
  height: 3px;
  background-color: ${COLORS.GREY_FOUR};
`;

export {
  expansionPanelBorderRadius,
  StyledAccordionFaq,
  StyledAccordion,
  StyledAccordionSummaryFaq,
  StyledAccordionSummary,
  StyledAccordionDetails,
  expandIconMargin,
  expandIconSize,
  DividerFaq,
  Divider,
};
