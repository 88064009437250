import styled, { css } from 'styled-components';

import { BREAKPOINTS, SPACE } from '../../constants';

const ContentBlockWrapper = styled.div`
  padding-bottom: 0;
  padding-top: ${SPACE.X2};
  width: 100%;

  @media (min-width: ${BREAKPOINTS.MD}px) {
    padding-bottom: ${SPACE.X3};
    padding-top: 0;
  }

  ${({ url = null, useBackgroundImage = false }) =>
    useBackgroundImage &&
    url &&
    css`
      background-image: url(${url});
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
    `};
`;

export { ContentBlockWrapper };
