import React from 'react';

import { COLORS } from '../../../constants';

const magnifyingGlass = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
    <path
      fill={COLORS.GREY_SEVEN}
      fillRule="evenodd"
      d="M12.665 11.234a.5.5 0 0 1-.055-.628c1.834-2.812 1.406-6.679-1.299-8.988C8.71-.604 4.748-.528 2.227 1.787c-2.894 2.656-2.968 7.178-.219 9.93 2.345 2.345 5.973 2.63 8.637.868a.497.497 0 0 1 .631.054l4.462 4.495a.494.494 0 0 0 .7.001l.697-.698a.494.494 0 0 0 .001-.699l-4.47-4.504zm-9.26-.915a4.905 4.905 0 0 1 0-6.925 4.895 4.895 0 0 1 6.919 0 4.905 4.905 0 0 1 0 6.925 4.896 4.896 0 0 1-6.92 0z"
    />
  </svg>
);

export default magnifyingGlass;
