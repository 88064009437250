import React from 'react';

const StarIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M11 16.466L16.871 20l-1.558-6.66L20.5 8.859l-6.83-.578L11 2 8.33 8.281 1.5 8.86l5.187 4.481L5.129 20z"
      />
    </g>
  </svg>
);

export default StarIcon;
