import React from 'react';

const DidYouKnowIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" {...props}>
    <g fill="none">
      <rect width="34" height="34" fill="#1EA2E5" rx="5" />
      <path
        fill="#FFF"
        d="M17.386 19.638c1.146 0 2.074-.929 2.074-2.074 0-1.145-.928-2.073-2.074-2.073-1.145 0-2.073.928-2.073 2.073s.928 2.074 2.073 2.074zm5.57-1.205c-.751.178-1.778.81-2.825 1.856-.77.77-1.461 1.935-1.461 3.772.02 2.192 6.023-1.975 5.924-4.976-.02-.376-.612-.889-1.639-.652zm-11.652-2.251c1.422-.257 3.811-1.481 6.2-1.481 1.68 0 3.476.79 4.681 1.145.415.119 1.007.336 1.856.375.316.02.632-.059.612-.335-.118-1.955-2.646-5.846-7.267-5.846-3.515 0-6.95 2.943-7.227 5.885-.04.356.69.316 1.145.257zm4.838 7.879c0-1.837-.69-3.002-1.461-3.772-1.047-1.027-2.054-1.678-2.824-1.836-1.027-.237-1.62.256-1.639.632-.099 3.001 5.924 7.168 5.924 4.976zM17.386 8.5c4.917 0 8.887 3.97 8.887 8.886 0 4.917-3.97 8.887-8.887 8.887S8.5 22.303 8.5 17.386 12.47 8.5 17.386 8.5zm0 7.623c.79 0 1.442.651 1.442 1.441s-.652 1.442-1.442 1.442c-.79 0-1.441-.632-1.441-1.442 0-.79.651-1.441 1.441-1.441z"
      />
    </g>
  </svg>
);

export default DidYouKnowIcon;
