import React from 'react';

const FacebookIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="22"
    viewBox="0 0 14 22"
    aria-labelledby="facebook-logo"
    role="img"
    {...props}
  >
    <title id="facebook-logo">Facebook</title>
    <path d="M8.822 21.901v-9.99h3.604l.54-3.893H8.821V5.532c0-1.127.336-1.895 2.074-1.895l2.215-.001V.153C12.728.106 11.413 0 9.883 0 6.688 0 4.502 1.814 4.502 5.146v2.872H.889v3.893h3.613v9.99h4.32z" />
  </svg>
);

export default FacebookIcon;
