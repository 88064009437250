import React from 'react';
import styled from 'styled-components';
import MuiBox from '@material-ui/core/Box';
import { spacing } from '@material-ui/system';

const StyledBox = styled(MuiBox)`
  ${spacing}
`;

const Box = ({ children, className, ...props }) => (
  <StyledBox {...props} className={className}>
    {children}
  </StyledBox>
);

export default Box;
