import ReCAPTCHA from 'react-google-recaptcha';
import React from 'react';
import styled from 'styled-components';

import muiTheme from '../../constants/mui-theme';
import { ExtraSmall } from '../type';

const HelperText = styled(ExtraSmall)`
  margin: 8px 14px 0;

  ${(props) =>
    props.error &&
    `
    color: ${muiTheme.palette.error.main};
  `}
`;

const Recaptcha = ({ error, helperText, ...props }) => {
  return (
    <>
      <ReCAPTCHA {...props} />
      <HelperText error={error}>{helperText}</HelperText>
    </>
  );
};

export default Recaptcha;
