import { CircularProgress, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingIndicator = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return (
      <Wrapper>
        <CircularProgress size={16} thickness={5} />
      </Wrapper>
    );
  }

  if (isTablet) {
    return (
      <Wrapper>
        <CircularProgress size={18} thickness={5} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <CircularProgress size={20} thickness={5} />
    </Wrapper>
  );
};

export default LoadingIndicator;
