import React from 'react';

const PlusIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      fill="#5C5C5C"
      d="M6 0c.51 0 .923.413.923.923v4.153h4.154a.923.923 0 1 1 0 1.847H6.923v4.154a.923.923 0 1 1-1.846 0l-.001-4.154H.923a.923.923 0 1 1 0-1.846l4.153-.001V.923C5.077.413 5.49 0 6 0z"
    />
  </svg>
);

export default PlusIcon;
