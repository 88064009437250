/**
 * Constructs string for use as a slug. Replaces spaces with hyphens, removes special characters and lowercases everything.
 * Note: This needs to match the Slug creation function in the CMS.
 * CMS file: src/modules/tac/utils/StringUtils.php
 *
 * @param {string} string A string we want to convert to a slug
 */
const constructSlug = (string) => {
  return string
    .replace(/[^a-zA-Z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase();
};

export default constructSlug;
