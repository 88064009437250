import { ApolloClient, ApolloError, HttpLink, InMemoryCache } from '@apollo/client';

export const createApolloClient = () => {
  const httpLink = new HttpLink({
    uri: process.env.APPSYNC_API_URL,
    headers: {
      'x-api-key': process.env.APPSYNC_API_KEY,
    },
  });

  const cache = new InMemoryCache();

  const client = new ApolloClient({
    link: httpLink,
    cache,
  });

  return client;
};

export const apolloAPIErrorHandler = (error) => {
  let errorMessage = '';

  if (error instanceof Error) {
    errorMessage = error.message;
  }

  if (error instanceof ApolloError) {
    const { networkError, graphQLErrors } = error;
    errorMessage = 'Error fetching data from server. Please try again.';

    if (graphQLErrors) {
      const errorsInString = graphQLErrors.map((err) => err.message);
      if (errorsInString.length > 0) {
        console.error('GraphQL errors', graphQLErrors);
        errorMessage = 'GraphQL errors: ' + graphQLErrors.map((err) => err.message).join(', ');
      }
    }

    if (networkError && 'statusCode' in networkError) {
      const { statusCode } = networkError;
      errorMessage = `Network error: ${statusCode}`;
    }
  }

  return errorMessage;
};
