import styled from 'styled-components';

import { BREAKPOINTS, COLORS, FONT_SIZE, GLOBAL } from '../../constants';

const StyledBreadcrumbs = styled.ul`
  color: ${COLORS.BRAND_BLUE_NINE};
  display: flex;
  font-family: ${GLOBAL.BRAND_FONT_FAMILY_SECOND};
  font-size: ${FONT_SIZE.X_SMALL};
  line-height: 16px;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (min-width: ${BREAKPOINTS.MD}px) {
    font-size: ${FONT_SIZE.SMALL};
    line-height: 18px;
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    font-size: ${FONT_SIZE.MEDIUM};
    line-height: 20px;
  }
`;

const Bold = styled.span`
  font-family: ${GLOBAL.BRAND_FONT_FAMILY};
`;

const StyledCrumbLink = styled.a`
  text-decoration: none;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const CrumbSeparator = styled.span`
  padding: 0 4px;

  @media (min-width: ${BREAKPOINTS.MD}px) {
    padding: 0 6px;
  }
`;

export { StyledBreadcrumbs, StyledCrumbLink, CrumbSeparator, Bold };
