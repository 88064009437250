import { keys } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import buildKeysObject from '../../utils/build-keys-object';

import CloseIcon from './close-icon';
import Caret from './caret';
import CompareCarIcon from './compare-car-icon';
import CrossIcon from './cross-icon';
import DidYouKnowIcon from './did-you-know-icon';
import ExpandIcon from './expand-icon';
import DownloadIcon from './download-icon';
import FacebookIcon from './facebook-icon';
import FilterIcon from './filter-icon';
import HamburgerIcon from './hamburger-icon';
import InstagramIcon from './instagram-icon';
import LinkActiveIcon from './link-active-icon';
import LinkDisabledIcon from './link-disabled-icon';
import MagnifyingGlass from './magnifying-glass';
import PlusIcon from './plus-icon';
import PointerIcon from './pointer-icon';
import QuestionMarkIcon from './question-mark-icon';
import SafetyFeatureFallbackIcon from './safety-feature-fallback-icon';
import StarDefaultIcon from './star-default-icon';
import StarIcon from './star-icon';
import StarOutlineIcon from './star-outline-icon';
import TickIcon from './tick-icon';
import TwitterIcon from './twitter-icon';
import Visibility from './visibility';
import VisibilityOff from './visibility-off';
import WarningIcon from './warning-icon';

const icons = {
  caret: Caret,
  close: CloseIcon,
  compareCar: CompareCarIcon,
  cross: CrossIcon,
  expand: ExpandIcon,
  download: DownloadIcon,
  didYouKnow: DidYouKnowIcon,
  facebook: FacebookIcon,
  filter: FilterIcon,
  hamburger: HamburgerIcon,
  instagram: InstagramIcon,
  linkActive: LinkActiveIcon,
  linkDisabled: LinkDisabledIcon,
  magnifyingGlass: MagnifyingGlass,
  plus: PlusIcon,
  pointer: PointerIcon,
  questionMark: QuestionMarkIcon,
  safetyFeatureFallback: SafetyFeatureFallbackIcon,
  star: StarIcon,
  starDefault: StarDefaultIcon,
  starOutline: StarOutlineIcon,
  tick: TickIcon,
  twitter: TwitterIcon,
  visibility: Visibility,
  visibilityOff: VisibilityOff,
  warning: WarningIcon,
};
export const iconNames = buildKeysObject(icons);

const Icon = ({ name, ...props }) => {
  const Component = icons[name];
  return <Component {...props} />;
};

Icon.propTypes = {
  ariaLabel: PropTypes.string,
  name: PropTypes.oneOf(keys(iconNames)).isRequired,
};

Icon.defaultProps = {
  ariaLabel: undefined,
};

export default Icon;
