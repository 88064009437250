import React from 'react';

const QuestionMarkIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" {...props}>
    <path
      fill="#5C5C5C"
      d="M0 3.256c0-.493.158-.993.475-1.5.317-.506.779-.925 1.387-1.258C2.469.166 3.178 0 3.988 0c.753 0 1.418.139 1.994.417.576.278 1.022.655 1.336 1.133.314.478.471.997.471 1.558 0 .441-.09.828-.269 1.16-.179.333-.392.62-.638.861l-.39.369c-.243.224-.555.507-.938.85a5.407 5.407 0 0 0-.425.425 1.724 1.724 0 0 0-.237.335 1.644 1.644 0 0 0-.121.303l-.041.159-.088.375c-.098.54-.407.81-.927.81a.948.948 0 0 1-.681-.265c-.185-.176-.277-.439-.277-.787 0-.436.068-.814.203-1.133.135-.32.314-.6.537-.841.186-.201.427-.434.72-.698l.56-.497c.145-.132.26-.24.344-.324.148-.148.273-.313.374-.495.101-.181.152-.379.152-.592 0-.415-.154-.766-.463-1.051-.31-.286-.708-.429-1.196-.429-.571 0-.992.145-1.262.433-.27.288-.498.712-.685 1.273-.177.587-.512.88-1.005.88A.988.988 0 0 1 .3 3.922c-.2-.205-.3-.427-.3-.666zm3.801 8.537c-.317 0-.593-.103-.83-.308-.236-.205-.354-.492-.354-.86 0-.328.114-.603.343-.826.228-.224.509-.335.841-.335.327 0 .602.111.826.335.223.223.335.498.335.825 0 .364-.117.65-.35.857a1.183 1.183 0 0 1-.81.312z"
    />
  </svg>
);

export default QuestionMarkIcon;
