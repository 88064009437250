import React from 'react';

const HamburgerIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" {...props}>
    <g fill="#1E1E1E" fillRule="evenodd">
      <path d="M19.008 16A.993.993 0 0 0 20 15c0-.552-.455-1-.992-1H.992A.993.993 0 0 0 0 15c0 .552.455 1 .992 1h18.016zM19.008 10A.993.993 0 0 0 20 9c0-.552-.455-1-.992-1H.992A.993.993 0 0 0 0 9c0 .552.455 1 .992 1h18.016zM0 3c0 .552.455 1 .992 1h18.016A.993.993 0 0 0 20 3c0-.552-.455-1-.992-1H.992A.993.993 0 0 0 0 3z" />
    </g>
  </svg>
);

export default HamburgerIcon;
