import { gql } from '@apollo/client';
import { createApolloClient, apolloAPIErrorHandler } from '../../apollo-api/client';
import { checkUcsrSaferPick } from '../../api';

export const getVehicles = async ({ make, model, year, vehicleIds }) => {
  try {
    const client = createApolloClient();
    const response = await client.query({
      variables: { year, make, model, vehicleIds },
      query: gql`
        query SearchVehicles($year: Int, $make: String, $model: String, $vehicleIds: [String]) {
          hsiycSearchVehiclesConnection(
            manufactureYear: $year
            vehicleMake: $make
            vehicleModel: $model
            vehicleIds: $vehicleIds
          ) {
            nodes {
              ancapRating
              badgeDescription
              derivedBodyStyle
              engineDisplacement
              image
              imageOverride
              isBaseOption
              isBlacklisted
              makeDescription
              modelDescription
              newPrice
              pPlateStatus
              privateMaxPrice
              privateMinPrice
              ratingProviderOverride
              redbookCode
              safetyFeaturesLinkedCount
              segment
              sequenceNumber
              series
              slug
              totalRecommendedSafetyFeatures
              transmission
              ucsrAggressivity
              ucsrDriver
              ucsrPrimarySafety
              ucsrRating
              vehicleDescription
              year
              uri
            }
          }
        }
      `,
    });

    // **BUSINESS LOGIC**
    // Get vehicles from response,
    // if the vehicle has a ucsr rating, check if it is less than 2,
    // if it is, return a new vehicle object with a ucsrSaferPick of false

    // NOTE: logic below is retained from the old API function (fetchPageVehicles)
    const vehicles = (response?.data?.hsiycSearchVehiclesConnection?.nodes ?? []).map((vehicle) => {
      if (vehicle.ucsrRating >= 0) {
        return checkUcsrSaferPick(vehicle);
      }

      return vehicle;
    });

    return { vehicles };
  } catch (err) {
    return apolloAPIErrorHandler(err);
  }
};

export const getVehicleVariants = async ({ make, model }) => {
  try {
    const client = createApolloClient();
    const response = await client.query({
      variables: { make, model },
      query: gql`
        query SearchVehicles($make: String!, $model: String!) {
          hsiycSearchVehiclesConnection(vehicleMake: $make, vehicleModel: $model) {
            nodes {
              redbookCode
              year
              vehicleDescription
              derivedBodyStyle
              bodyStyle
              transmission
              fuelType
              series
              badgeDescription
              engineDisplacement
              sequenceNumber
              isBaseOption
              slug
            }
          }
        }
      `,
    });

    const vehicles = response?.data?.hsiycSearchVehiclesConnection?.nodes ?? [];

    return vehicles;
  } catch (err) {
    return apolloAPIErrorHandler(err);
  }
};
