import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { LOCALSTORAGE_KEYS, SECTION_HANDLES } from '../../constants';
import { GRAPHQL_IMAGE_FIELDS } from '../../utils/get-vehicle-image';
import Minicart from '../mini-cart';
import { CART_MAXIMUM } from '../mini-cart/constants';
import { getVehicles } from '../../utils/apollo-requests/vehicles/get-vehicles';

const addVehicleIdsToLocalStorage = (vehicleIds) => {
  if (vehicleIds && vehicleIds.length) {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      try {
        localStorage.setItem(LOCALSTORAGE_KEYS.COMPARE, JSON.stringify(vehicleIds));
      } catch (e) {
        // localStorage is disabled
      }
    }
  }
};

const MiniCartWrapper = ({ compareVehicleIds, filterActions, isOpen, onClose, removeFromCompare }) => {
  const compareVehicleCount = _.size(compareVehicleIds);
  const [VehicleData, setVehicleData] = useState([]);

  let vehicleIds;
  if (!_.isEmpty(compareVehicleIds)) {
    vehicleIds = compareVehicleIds;
  }

  useEffect(() => {
    let isSubscribed = true;

    addVehicleIdsToLocalStorage(vehicleIds);

    if (vehicleIds) {
      const getCarData = async () => {
        const query = await getVehicles({ vehicleIds });
        if (isSubscribed) {
          setVehicleData(query.vehicles);
        }
      };
      getCarData();
    }

    return () => {
      isSubscribed = false;
    };
  }, [vehicleIds]);

  const vehicles = vehicleIds ? VehicleData : [];

  let alertMessage = '';
  if (compareVehicleCount === 1) {
    alertMessage = "There aren't enough cars in your list to compare. Select one more.";
  } else if (compareVehicleCount > CART_MAXIMUM) {
    alertMessage = 'Too many vehicles to compare. The maximum number of vehicles is 6.';
  }

  return (
    <Minicart
      {...{
        filterActions,
        isOpen,
        removeFromCompare,
        vehicles,
        vehicleIds,
        compareVehicleCount,
        onClose,
        alertMessage,
      }}
    />
  );
};

MiniCartWrapper.propTypes = {
  compareVehicleIds: PropTypes.arrayOf(PropTypes.string),
  filterActions: PropTypes.shape({
    addLifestyle: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    updateSelectedPriceRange: PropTypes.func.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  removeFromCompare: PropTypes.func.isRequired,
};

MiniCartWrapper.defaultProps = {
  compareVehicleIds: [],
  isOpen: false,
};

export default MiniCartWrapper;
