import _ from 'lodash';

const hydrateVehicleIdsStateFromObject = (vehicleIds, compareActions) => {
  const { addToCompare } = compareActions;
  _.forEach(vehicleIds, (vehicleId) => {
    addToCompare(vehicleId);
  });
};

const hydrateVehicleIdsStateFromUrl = (queryParams, compareActions) => {
  const { vehicles } = queryParams;
  const vehicleIds = vehicles.split(',');

  hydrateVehicleIdsStateFromObject(vehicleIds, compareActions);
};

export { hydrateVehicleIdsStateFromObject, hydrateVehicleIdsStateFromUrl };
