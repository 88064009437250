import { LinearProgress } from '@material-ui/core';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// LinearProgress reference: https://material-ui.com/api/linear-progress/
const StyledLinearProgress = styled(LinearProgress)`
  &.MuiLinearProgress-root {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
  }
`;

// Reference: https://stackoverflow.com/a/59117532
const Loading = () => {
  const barRef = useRef();
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!router) {
      return () => {};
    }
    const handleStart = (url) => url !== router.pathname && setLoading(true);
    const handleComplete = (url) => url !== router.pathname && setLoading(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  });

  useEffect(() => {
    if (barRef.current && loading) {
      barRef.current.focus();
    }
  }, [barRef, loading]);

  return (
    loading && (
      <StyledLinearProgress
        variant="indeterminate"
        aria-label="Page loading"
        aria-live="assertive"
        tabIndex="-1"
        ref={barRef}
      />
    )
  );
};

export default Loading;
