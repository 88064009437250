import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';

export const useGutterSpacing = () => {
  const [spacing, setSpacing] = useState(2);

  const theme = useTheme();

  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const xlUp = useMediaQuery(theme.breakpoints.up('xl'));

  useEffect(()=>{
    if (smUp) {
      setSpacing(4);
    }

    if (mdUp) {
      setSpacing(4); // 16px
    }

    if (lgUp) {
      setSpacing(5); // 20px
    }

    if (xlUp) {
      setSpacing(6); // 24px
    }
  },[lgUp, mdUp, smUp, xlUp])

  return spacing;
};
