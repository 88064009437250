import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H4Styles, AStyles, PStyles } from '../type';
import { COLORS, FONT_WEIGHT, SPACE } from '../../constants';

const StyledRichText = styled.div`
  & h2 {
    ${H4Styles};
    letter-spacing: normal;
    margin-top: ${SPACE.X2};
    margin-bottom: calc(${SPACE.X1} + ${SPACE.X0});
  }

  & a {
    ${AStyles};
    color: ${COLORS.BRAND_BLUE_EIGHT};
    font-weight: ${FONT_WEIGHT.BOLD};
    text-decoration: underline;
  }

  & figure,
  img {
    margin: ${SPACE.X2} 0;
    max-width: 100%;
  }

  & ul,
  ol {
    ${PStyles};
    margin-left: 0;
    padding-inline-start: 0;
    padding-left: ${SPACE.X2};
  }

  & li {
    list-style-position: outside;
    margin-bottom: ${SPACE.X1};
    padding-left: ${SPACE.X0};
  }
`;

const RichText = ({ children, className }) => {
  return <StyledRichText {...{ className }} dangerouslySetInnerHTML={{ __html: children }} />;
};

RichText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

RichText.defaultProps = {
  children: null,
  className: null,
};

export default RichText;
