import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCardImage = styled.img`
  height: auto;
  width: 100%;
`;

const CardImage = ({ src, alt }) => <StyledCardImage {...{ src, alt }} />;

export default CardImage;

CardImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
};

CardImage.defaultProps = {
  src: '/images/car-image-not-found.svg',
};
