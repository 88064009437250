import React from 'react';

import Box from './box';

const Flex = ({ children, className, ...props }) => (
  <Box {...props} className={className} display="flex">
    {children}
  </Box>
);

export default Flex;
