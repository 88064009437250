import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { SPACE } from '../../constants';
import { IconButton } from '../icon-button';
import { iconNames } from '../icons';

const StyledIconButton = styled(IconButton)`
  background: transparent;
  &.MuiButtonBase-root {
    display: block;
    position: absolute;
    left: ${({ anchor }) => anchor === 'left' && `${SPACE.X1}`};
    right: ${({ anchor }) => anchor === 'right' && `${SPACE.X1}`};
    top: ${SPACE.X1};
    z-index: 1;
  }
`;

const Panel = ({ anchor, ariaLabel, ariaLabelledBy, children, isOpen, onClose }) => {
  const useStyles = makeStyles({
    root: {
      width: '584px',
      maxWidth: '90%',
      minHeight: '100%',
    },
  });

  const paperStyle = useStyles();

  return (
    <Drawer
      anchor={anchor}
      classes={{ paper: paperStyle.root }}
      open={isOpen}
      onClose={onClose}
      role="dialog"
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      variant="temporary"
    >
      <StyledIconButton
        anchor={anchor}
        ariaLabel="Close navigation"
        className="close-panel"
        iconName={iconNames.close}
        name="Close navigation"
        onClick={onClose}
      />
      {children}
    </Drawer>
  );
};

Panel.propTypes = {
  ariaLabel: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  anchor: PropTypes.oneOf(['left', 'right']),
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

Panel.defaultProps = {
  ariaLabel: null,
  ariaLabelledBy: null,
  anchor: 'left',
  isOpen: false,
  children: null,
  onClose: () => {},
};

export { Panel };
