import _ from 'lodash';

const openMiniCart = (state) => {
  return _.assign({}, state, {
    global: {
      isMiniCartOpen: true,
    },
  });
};

const closeMiniCart = (state) => {
  return _.assign({}, state, {
    global: {
      isMiniCartOpen: false,
    },
  });
};

export default { openMiniCart, closeMiniCart };
