import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from '../grid';
import RichText from '../rich-text';

import { StyledContainer, StyledH3 } from './styles';

const ContentBlock = ({ heading, body }) => {
  return (
    <StyledContainer>
      <Row>
        <Col xs={false} lg={1} />
        <Col xs={12} lg={8}>
          {heading && <StyledH3 as="h2">{heading}</StyledH3>}
          {body && <RichText>{body}</RichText>}
        </Col>
      </Row>
    </StyledContainer>
  );
};

ContentBlock.query = `
  ... on contentMatrix_contentBlock_BlockType {
    id
    typeHandle
    heading
    body
  }
  `;

ContentBlock.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string,
};

ContentBlock.defaultProps = {
  heading: null,
  body: null,
};

export default ContentBlock;
