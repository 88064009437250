import React from 'react';
import MuiIconButton from '@material-ui/core/IconButton';

import Icon, { iconNames } from '../icons';

const IconButton = ({ ariaLabel, className, edge, iconName, onClick, name }) => (
  // https://material-ui.com/api/icon-button/#props
  <MuiIconButton edge={edge} aria-label={ariaLabel} {...{ onClick, className, name }}>
    <Icon name={iconNames[iconName]} />
  </MuiIconButton>
);

export { IconButton };
