const getIsZeroStarAncapRating = ({ makeDescription, modelDescription, year }) => {
  if (makeDescription === 'Mitsubishi') {
    return modelDescription === 'Express' && ['2021', '2020'].includes(year);
  }
  if (makeDescription === 'Mahindra') {
    return modelDescription === 'Scorpio' && ['2023'].includes(year);
  }
  if (makeDescription === 'MG') {
    return modelDescription === 'MG5' && ['2023'].includes(year);
  }

  return false;
};

export default getIsZeroStarAncapRating;
