import _ from 'lodash';

const setCacheState = (data, isLoading, isError) => ({ isLoading, isError, data });

const addCache = (state, payload) => {
  return {
    ...state,
    [_.keys(payload)[0]]: setCacheState(_.values(payload)[0], false, false),
  };
};

const invalidateCache = (state, key) => {
  return {
    ...state,
    [key]: setCacheState(null, true, false),
  };
};

const errorCache = (state, key) => {
  return {
    ...state,
    [key]: setCacheState(null, false, true),
  };
};

export default { addCache, invalidateCache, errorCache, setCacheState };
