import { useState } from 'react';

export const useAccordion = () => {
  const [openAccordions, setOpenAccordions] = useState(false);

  const handleChange = (accordionIndex) => {
    const isOpen = !!openAccordions[accordionIndex];
    setOpenAccordions({ ...openAccordions, [accordionIndex]: !isOpen });
  };

  return [openAccordions, handleChange];
};
