import styled from 'styled-components';

import { BREAKPOINTS } from '../../constants';
import Icon from '../icons';

const SIZE_STYLES = `
  height: 18px;
  min-height: 18px;
  width: 18px;
  min-width: 18px;
  @media (min-width: ${BREAKPOINTS.SM}px) {
    height: 22px;
    min-height: 22px;
    width: 22px;
    min-width: 22px;
  }
`;

const Wrapper = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${SIZE_STYLES}
`;

const StyledIcon = styled(Icon)`
  height: 8px;
  width: 8px;
  @media (min-width: ${BREAKPOINTS.SM}px) {
    width: 10px;
    height: 10px;
  }
`;

/*
  The warning icon is a little weird since it should look and behave like the other "icons with backgrounds".
  Except instead of being an icon with a CSS background circle, it's actually an icon that has a background circle in the SVG itself.
  So, to make it behave like the others, it gets some bespoke styling.
*/
const StyledWarningIcon = styled(Icon)`
  ${SIZE_STYLES}
`;

export { Wrapper, StyledIcon, StyledWarningIcon };
