import React from 'react';

const Caret = (props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    aria-labelledby="caret-logo"
    role="img"
    {...props}
  >
    <title id="caret-logo">Caret pointing down icon</title>
    <path d="M16.094 19.2l11.859-11.859 2.635 2.635-14.494 14.682-14.682-14.871 2.635-2.447z" />
  </svg>
);

export default Caret;
