import { scroller } from 'react-scroll';
import { withFormik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { PrimaryButton } from '../button';
import { Row, Col } from '../grid';
import { TextField, Recaptcha } from '../form-fields';
import { ControlledSelect } from '../form-fields/select';
import formValidation from '../../utils/form-validation';
import Alert from '../alert';
import { ALERT_STATUSES } from '../../constants/statuses';

const getSiteKey = () => process.env.RECAPTCHA_SITE_KEY || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'; // do not remove - required for recaptcha in storybook

const StyledCol = styled(Col)`
  padding-bottom: 1rem;
  position: relative;
`;

const ErrorCol = styled(Col)`
  position: relative;
`;

const states = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
const options = _.map(states, (state) => ({ value: state, label: state }));

const colWidth = { xs: 12, lg: 10, xl: 8 };
const colOffset = [0, 0, 0, 1 / 12, 2 / 12];
const semiColWidth = { xs: 12, md: 6, lg: 5, xl: 4 };

const setFocus = (ref) =>
  setTimeout(() => {
    ref.current.focus();
  }, 100);

const Form = ({
  errors,
  handleChange,
  handleReset,
  handleSubmit,
  isSubmitting,
  setStatus,
  status,
  successMessage,
  touched,
  values,
}) => {
  const fNameRef = useRef();
  const lNameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();

  const scrollTo = (id) => {
    if (document.getElementById(id)) {
      scroller.scrollTo(id, {
        duration: 800,
        offset: -50,
        smooth: true,
      });
    }
  };

  React.useEffect(() => {
    const validationErrors = Object.keys(errors);

    if (isSubmitting) {
      if (status === 'success') {
        scrollTo('form-success');
      } else if (validationErrors.length > 0) {
        if (!errors.general) {
          scrollTo(_.omit(validationErrors, 'general')[0]);
        }
      }
    }
  }, [status]);

  const resetForm = (props) => {
    setStatus(null);
    scrollTo('form-success');
    handleReset({ ...props });
  };

  const isSuccess = status === 'success';

  useEffect(() => {
    if (fNameRef && errors.firstName) {
      setFocus(fNameRef);
    } else if (lNameRef && errors.lastName) {
      setFocus(lNameRef);
    } else if (emailRef && errors.fromEmail) {
      setFocus(emailRef);
    } else if (messageRef && errors.message) {
      setFocus(messageRef);
    }
  }, [fNameRef, lNameRef, emailRef, messageRef, isSubmitting]);

  return (
    <>
      {isSuccess && (
        <Row wrap="wrap" id="form-success">
          <StyledCol width={colWidth} offset={colOffset}>
            <Alert id="success" status={ALERT_STATUSES.success} content={successMessage} />
          </StyledCol>
          <StyledCol width={semiColWidth} offset={colOffset}>
            <PrimaryButton type="button" disabled={isSubmitting} onClick={resetForm}>
              Reset
            </PrimaryButton>
          </StyledCol>
        </Row>
      )}
      {!isSuccess && (
        <form onSubmit={handleSubmit} autoComplete="on" aria-label="contact form">
          <Row wrap="wrap">
            <StyledCol width={semiColWidth} offset={colOffset}>
              <TextField
                error={touched.firstName && Boolean(errors.firstName)}
                fullWidth
                helperText={touched.firstName ? errors.firstName : ''}
                id="firstName"
                label="First Name"
                onChange={handleChange}
                value={values.firstName}
                disabled={isSubmitting}
                autoComplete="given-name"
                inputRef={fNameRef}
              />
            </StyledCol>
            <StyledCol width={semiColWidth}>
              <TextField
                error={touched.lastName && Boolean(errors.lastName)}
                fullWidth
                helperText={touched.lastName ? errors.lastName : ''}
                id="lastName"
                label="Last Name"
                onChange={handleChange}
                value={values.lastName}
                disabled={isSubmitting}
                autoComplete="family-name"
                inputRef={lNameRef}
              />
            </StyledCol>
            <StyledCol width={semiColWidth} offset={colOffset}>
              <TextField
                error={touched.fromEmail && Boolean(errors.fromEmail)}
                fullWidth
                helperText={touched.fromEmail ? errors.fromEmail : ''}
                id="fromEmail"
                label="Email"
                onChange={handleChange}
                type="email"
                value={values.fromEmail}
                disabled={isSubmitting}
                autoComplete="email"
                inputRef={emailRef}
              />
            </StyledCol>
            <StyledCol width={semiColWidth}>
              <ControlledSelect
                error={touched.state && Boolean(errors.state)}
                helperText={touched.state ? errors.state : ''}
                id="state"
                label="State"
                onChange={handleChange('state')}
                options={options}
                value={values.state}
              />
            </StyledCol>
            <StyledCol width={colWidth} offset={colOffset}>
              <TextField
                error={touched.message && Boolean(errors.message)}
                fullWidth
                helperText={touched.message ? errors.message : ''}
                id="message"
                label="Message"
                multiline
                onChange={handleChange}
                rows="4"
                value={values.message}
                disabled={isSubmitting}
                inputRef={messageRef}
              />
            </StyledCol>
            <StyledCol width={semiColWidth} offset={colOffset}>
              <Recaptcha
                error={touched.recaptcha && Boolean(errors.recaptcha)}
                helperText={touched.recaptcha ? errors.recaptcha : ''}
                id="recaptcha"
                sitekey={getSiteKey()}
                onChange={handleChange('recaptcha')}
              />
            </StyledCol>
            <StyledCol width={semiColWidth}>
              <PrimaryButton type="submit" disabled={isSubmitting} isLoading={isSubmitting} fullWidth>
                {isSubmitting ? 'Submitting…' : 'Submit'}
              </PrimaryButton>
            </StyledCol>
            {errors.general && (
              <ErrorCol id="general" width={colWidth} offset={colOffset}>
                <Alert id="error" status={ALERT_STATUSES.error} content={errors.general} />
              </ErrorCol>
            )}
          </Row>
        </form>
      )}
    </>
  );
};

export default withFormik({
  mapPropsToValues: ({ firstName, lastName, fromEmail, state, message, recaptcha, subject }) => {
    return {
      action: 'contact-form/send',
      firstName: firstName || '',
      lastName: lastName || '',
      fromEmail: fromEmail || '',
      state: state || '',
      message: message || '',
      recaptcha: recaptcha || null,
      ...(subject && { subject }),
    };
  },

  validate: ({ recaptcha }) => {
    const errors = {};
    if (!recaptcha) {
      errors.recaptcha = 'reCAPTCHA is required';
    }
    return errors;
  },

  validationSchema: Yup.object().shape({
    firstName: formValidation.requiredFirstName,
    lastName: formValidation.requiredLastName,
    fromEmail: formValidation.requiredEmail,
    message: formValidation.requiredMessage,
  }),

  handleSubmit: async (values, bag) => {
    const { props } = bag;
    const { message, state, firstName, lastName, recaptcha, ...rest } = values;
    const formData = {
      'message[State]': state,
      'message[Body]': message,
      fromName: `${firstName} ${lastName}`,
      'g-recaptcha-response': recaptcha,
      ...rest,
    };

    await props.handleSubmit(formData, bag);
  },
})(Form);
