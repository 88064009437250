import React from 'react';
import _ from 'lodash';

import { fetchDataReducer } from '../reducers/fetch-data-reducer';
import { getVehicleAncapTestRecords } from '../utils/apollo-requests/vehicles/get-vehicle-ancap-test-records';
import logger from '../utils/logger';

const useAncapRecord = (pageVehicle, ssrAncapRecord) => {
  const [state, dispatch] = React.useReducer(fetchDataReducer, {
    isLoading: !ssrAncapRecord,
    hasError: false,
    data: ssrAncapRecord || {},
  });

  React.useEffect(() => {
    let isSubscribed = true;

    const shouldFetch = !ssrAncapRecord || _.isEmpty(state.data);

    if (!pageVehicle) {
      logger.error('No Page Vehicle when fetching ANCAP Record', { ssrAncapRecord });
      dispatch({ type: 'FETCH_FAIL' });
    }

    if (pageVehicle && shouldFetch) {
      dispatch({ type: 'FETCH_INIT' });
      getVehicleAncapTestRecords(pageVehicle.redbookCode)
        .then((response) => {
          if (isSubscribed) {
            dispatch({
              type: 'FETCH_SUCCESS',
              payload: response,
            });
          }
        })
        .catch((err) => {
          logger.error('Error fetching ANCAP Record', err, { pageVehicle, ssrAncapRecord });
          if (isSubscribed) {
            dispatch({ type: 'FETCH_FAIL' });
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [pageVehicle]);

  return state;
};

export default useAncapRecord;
