import { first, last } from 'lodash';

import { yearSliderMarks } from '../discovery-filter/constants';
import { priceRangeSliderMarks } from '../discovery-questions-input/utils/price-range-slider-marks';

const CART_MAXIMUM = 6;

const PRICE_MIN = first(priceRangeSliderMarks).value;
const PRICE_MAX_P_PLATER = 10000;
const PRICE_MAX_YOUNG_FAMILY = 30000;
const YEAR_MIN = first(yearSliderMarks).value;
const YEAR_MAX = last(yearSliderMarks).value;

const P_PLATER_APPROVED__QUERY = {
  price: [PRICE_MIN, PRICE_MAX_P_PLATER],
  pPlaterApproved: true,
  year: [YEAR_MIN, YEAR_MAX],
};
const SAFE_CARS_FOR_FAMILIES_QUERY = {
  price: [PRICE_MIN, PRICE_MAX_YOUNG_FAMILY],
  safeCarsForFamilies: true,
  year: [YEAR_MIN, YEAR_MAX],
};
const pPlaterSearchParams = new URLSearchParams(Object.entries(P_PLATER_APPROVED__QUERY));
const familiesSearchParams = new URLSearchParams(Object.entries(SAFE_CARS_FOR_FAMILIES_QUERY));

const DISCOVERY_LINKS = {
  NO_VEHICLE: [
    {
      linkText: 'Safe first car for P-platers',
      href: `/results?${pPlaterSearchParams.toString()}`,
      price: 'under $10,000',
    },
    {
      linkText: 'Safe cars for young families',
      href: `/results?${familiesSearchParams.toString()}`,
      price: 'under $30,000',
    },
  ],
  SINGLE_VEHICLE: [
    {
      linkText: 'Search for another car to add',
      href: `/`,
    },
  ],
  MULTIPLE_VEHICLES: [
    {
      linkText: 'Add another car',
      href: `/`,
    },
  ],
};

export { CART_MAXIMUM, DISCOVERY_LINKS };
