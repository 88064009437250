import { init } from '@rematch/core';

import models from '../models';

const initializeStore = (initialState = {}) => {
  return init({
    models,
    redux: {
      initialState,
    },
  });
};

export default initializeStore;
