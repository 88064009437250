import React from 'react';

import BaseContentMatrix, { defaultProps, basePropTypes } from '../base-content-matrix';
import { buildContentMatrixElementQueryString } from '../../utils/content-matrix';
import AccordionBlock from '../accordion-block';
import ContactFormBlock from '../contact-form-block';
import ContentBlock from '../content-block';
import ContentGridBlock from '../content-grid-block';
import ContentMediaBlock from '../content-media-block';
import SafetyFeatureShowcaseBlock from '../safety-feature-showcase-block';

const CONTENT_MATRIX_MAPPING = {
  accordionBlock: AccordionBlock,
  contactFormBlock: ContactFormBlock,
  contentBlock: ContentBlock,
  contentGridBlock: ContentGridBlock,
  contentMediaBlock: ContentMediaBlock,
  safetyFeatureShowcaseBlock: SafetyFeatureShowcaseBlock,
};

const ContentMatrix = (props) => <BaseContentMatrix {...{ ...props, elementMapping: CONTENT_MATRIX_MAPPING }} />;

ContentMatrix.propTypes = basePropTypes;
ContentMatrix.defaultProps = defaultProps;

ContentMatrix.query = `
  contentMatrix {
    typeName: __typename
    ${buildContentMatrixElementQueryString(CONTENT_MATRIX_MAPPING)}
  }
`;

export default ContentMatrix;
export { CONTENT_MATRIX_MAPPING };
