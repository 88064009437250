import _ from 'lodash';

const addToCompare = (state, vehicleId) => {
  if (_.includes(state.compareVehicleIds, vehicleId)) {
    return state;
  }

  return _.assign({}, state, {
    compareVehicleIds: _.concat(state.compareVehicleIds, vehicleId),
  });
};

const removeFromCompare = (state, vehicleId) => {
  return _.assign({}, state, {
    compareVehicleIds: _.without(state.compareVehicleIds, vehicleId),
  });
};

export default { addToCompare, removeFromCompare };
