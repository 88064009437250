import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import Link from 'next/link';

import { COLORS, BOX_SHADOW, FONT_SIZE, GLOBAL, SPACE, URLS, BREAKPOINTS } from '../../constants';
import Navigation from '../navigation';
import Logos from '../logos';
import CompareCars from '../compare-cars';
import { logTrackingEvent } from '../../utils/tracking';

const StyledWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  box-shadow: ${BOX_SHADOW.NAVIGATION};
  z-index: 1;
`;

const StyledGrid = styled(Grid)`
  min-height: ${GLOBAL.HEADER_HEIGHT[0]};

  @media (min-width: ${BREAKPOINTS.MD}px) {
    min-height: ${GLOBAL.HEADER_HEIGHT[1]};
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    min-height: ${GLOBAL.HEADER_HEIGHT[2]};
  }
`;

const StyledA = styled.a`
  display: inherit;
`;

const StyledBanner = styled.div`
  width: 100%;
  background-color: ${COLORS.BRAND_NAVY_ONE};
`;

const StyledBannerText = styled.div`
  color: ${COLORS.BLACK};
  text-align: center;
  font-size: ${FONT_SIZE.X_SMALL};
  padding: ${SPACE.X0};
  margin: auto;
  & a {
    text-decoration: underline;
  }
  @media (min-width: ${BREAKPOINTS.LG}px) {
    font-size: ${FONT_SIZE.MEDIUM};
    max-width: 800px;
  }
`;

const Header = ({ isHomePage, primaryNavProps, secondaryNavProps, socialLinksProps, compareButtonProps }) => {
  const theme = useTheme();

  const useStyles = makeStyles({
    logos: {
      position: 'absolute',
      height: '40px',
      left: '50%',
      transform: 'translateX(-50%)',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
        order: 1,
        position: 'static',
        height: 'auto',
        left: '0',
        transform: 'translateX(0)',
      },
    },
    navigation: {
      justifyContent: 'space-between',
      [theme.breakpoints.up('md')]: {
        order: 2,
      },
    },
    compareCars: {
      justifyContent: 'flex-end',
      order: 3,
      padding: `${SPACE.X1} 0`,
    },
  });
  const classes = useStyles();

  return (
    <StyledWrapper>
      <Container maxWidth="xl">
        <StyledGrid container justify="space-between" alignItems="center">
          <Grid item container xs={8} md={4} className={classes.logos}>
            <Link href={URLS.HOME} passHref>
              <StyledA>
                <Logos {...{ isHomePage }} />
              </StyledA>
            </Link>
          </Grid>
          <Grid item container xs="auto" md={8} className={classes.navigation} alignItems="center">
            <Navigation {...{ primaryNavProps, secondaryNavProps, socialLinksProps }} />
            <CompareCars {...{ compareButtonProps }} />
          </Grid>
        </StyledGrid>
      </Container>
    <StyledBanner>
        <StyledBannerText>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          The annual update to <strong>Used Car Safety Ratings</strong> is out now, view the{' '}
          <a
            href="/files/used-car-safety-ratings-2024.pdf"
            target="_blank"
            onClick={() =>
              logTrackingEvent({
                Category: 'Header',
                Action: 'Banner:Clicked',
                Label: 'UCSR Report 2024',
              })
            }
          >
            2024&nbsp;UCSR&nbsp;Brochure&nbsp;-&nbsp;PDF&nbsp;(3.2&nbsp;MB)
          </a>
          .
        </StyledBannerText>
      </StyledBanner>
    </StyledWrapper>
  );
};

Header.propTypes = {
  isHomePage: PropTypes.bool,
  primaryNavProps: PropTypes.shape({
    links: PropTypes.array,
  }).isRequired,
  secondaryNavProps: PropTypes.shape({
    links: PropTypes.array,
    titleSecondary: PropTypes.string,
  }),
  socialLinksProps: PropTypes.shape({
    socialIcons: PropTypes.array,
    tagline: PropTypes.string,
  }),
  compareButtonProps: PropTypes.shape({
    count: PropTypes.number,
    onOpenComparison: PropTypes.func,
  }).isRequired,
};

Header.defaultProps = {
  isHomePage: false,
  secondaryNavProps: {
    links: [],
    title: '',
  },
  socialLinksProps: {
    socialIcons: [],
    tagline: '',
  },
};

export default Header;
