import React from 'react';

const TickIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" {...props}>
    <path
      fill="#FCFCFC"
      d="M1.754 4.093A1 1 0 1 0 .246 5.406l3.578 4.112L10.75 1.66A1 1 0 1 0 9.25.34L3.834 6.482l-2.08-2.39z"
    />
  </svg>
);

export default TickIcon;
