import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import { FONT_SIZE, SPACE } from '../../constants';
import Icon from '../icons';

const StyledSocialLink = styled.a`
  border: 1px solid transparent;
  display: inline-block;
  margin-left: ${SPACE.X1};
  vertical-align: middle;

  @media (min-width: 375px) {
    margin-left: ${SPACE.X2};
  }
`;

const StyledTagline = styled.span`
  font-size: ${FONT_SIZE.SMALL};
  line-height: 18px;
`;

const SocialLinks = ({ className, socialIcons, tagline }) => (
  <div className={className}>
    <StyledTagline>{tagline}</StyledTagline>
    {_.map(socialIcons, ({ iconName, url }) => (
      <StyledSocialLink key={iconName} href={url} aria-label={`link to ${iconName}`}>
        <Icon name={iconName} />
      </StyledSocialLink>
    ))}
  </div>
);

export default SocialLinks;

SocialLinks.propTypes = {
  className: PropTypes.string,
  socialIcons: PropTypes.arrayOf(PropTypes.object),
  tagline: PropTypes.string,
};

SocialLinks.defaultProps = {
  className: '',
  socialIcons: [],
  tagline: '',
};
