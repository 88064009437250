import React from 'react';

const InstagramIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    aria-labelledby="instagram-logo"
    role="img"
    {...props}
  >
    <title id="instagram-logo">Instagram</title>
    <path d="M19.38 0H2.614C1.17 0 0 1.15 0 2.57v16.536c0 1.42 1.17 2.57 2.614 2.57H19.38c1.444 0 2.615-1.15 2.615-2.57V2.571C21.995 1.15 20.825 0 19.38 0zM15.81 3.387c0-.374.308-.677.687-.677h2.062c.38 0 .687.303.687.677v2.032a.682.682 0 0 1-.687.677h-2.062a.682.682 0 0 1-.687-.677V3.387zm-4.786 3.318c2.336 0 4.23 1.862 4.23 4.159 0 2.297-1.894 4.158-4.23 4.158-2.336 0-4.23-1.861-4.23-4.158 0-2.297 1.894-4.16 4.23-4.16zm8.91 12.262a.683.683 0 0 1-.688.678H2.75a.683.683 0 0 1-.687-.678V8.806h2.75c-.359.508-.48 1.456-.48 2.058 0 3.627 3.002 6.578 6.69 6.578 3.69 0 6.692-2.951 6.692-6.578 0-.602-.087-1.535-.53-2.058h2.749v10.161z" />
  </svg>
);

export default InstagramIcon;
