import React from 'react';

import { COLORS } from '../../../constants';

const StarOutlineIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={COLORS.BLACK}
        fillRule="nonzero"
        d="M21 8.811L13.811 8.2 11 1.6 8.189 8.2 1 8.811l5.467 4.711-1.645 7.011L11 16.823l6.178 3.71-1.645-7.01L21 8.81zm-10 6.667l-4.444 2.744 1.11-5.1L3.723 9.7l5.234-.444L11 4.433l2.044 4.823 5.234.444-3.945 3.422 1.2 5.1L11 15.478z"
      />
    </g>
  </svg>
);

export default StarOutlineIcon;
