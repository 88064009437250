import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import _ from 'lodash';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import { LINKS } from '../../constants';

import { ANCAPLogo, RedbookLogo, TACLogo, UCSRCombinedLogo, VicLogo } from './logos';
import {
  ContentWrapper,
  FooterLink,
  FooterLinks,
  FooterWrapper,
  FocusA,
  LinksLabel,
  LinkWrapper,
  List,
  ListLink,
  Logos,
  LogoWrapper,
  LogoListItem,
  Pipe,
  SocialLink,
  StyledIcon,
  TermItem,
  TermsLink,
} from './styles.js';

const Footer = ({ termsAndPrivacyLinks, socialLinks, pageLinks }) => {
  const pageLinksGrouped = _.chunk(pageLinks, 2);

  return (
    <FooterWrapper>
      <ContentWrapper>
        <Container maxWidth="xl">
          <Grid container>
            <Grid item xs={12} sm={6}>
              <LinksLabel role="heading" aria-level="2">
                Join the conversation
              </LinksLabel>
              <List data-testid="social-links-list">
                {socialLinks &&
                  _.map(socialLinks, (link, index) => (
                    <SocialLink key={index}>
                      <FocusA href={link.url} target="_blank" rel="noopener noreferrer">
                        <StyledIcon name={link.iconName} />
                      </FocusA>
                    </SocialLink>
                  ))}
              </List>
              <Logos>
                <LogoListItem>
                  <LogoWrapper href={LINKS.TAC} target="_blank" rel="noopener noreferrer">
                    <TACLogo />
                  </LogoWrapper>
                </LogoListItem>
                <LogoListItem>
                  <LogoWrapper href={LINKS.VICTORIA_GOVERNMENT} target="_blank" rel="noopener noreferrer" shortened>
                    <VicLogo />
                  </LogoWrapper>
                </LogoListItem>
                <LogoListItem>
                  <LogoWrapper href={LINKS.ANCAP} target="_blank" rel="noopener noreferrer">
                    <ANCAPLogo />
                  </LogoWrapper>
                </LogoListItem>
                <LogoListItem>
                  <LogoWrapper target="_blank" rel="noopener noreferrer">
                    <UCSRCombinedLogo />
                  </LogoWrapper>
                </LogoListItem>
                <LogoListItem>
                  <LogoWrapper target="_blank" rel="noopener noreferrer" shortened>
                    <RedbookLogo />
                  </LogoWrapper>
                </LogoListItem>
              </Logos>
            </Grid>

            <Hidden xsDown>
              <Grid item sm={6}>
                <LinksLabel role="heading" aria-level="2">
                  More about
                </LinksLabel>
                {pageLinks && (
                  <ListLink>
                    {_.map(pageLinksGrouped, (links) =>
                      _.map(links, (link, i) => (
                        <li key={i}>
                          <LinkWrapper>
                            <Link href={link.url} passHref>
                              <FooterLink href={link.url}>{link.title}</FooterLink>
                            </Link>
                          </LinkWrapper>
                        </li>
                      ))
                    )}
                  </ListLink>
                )}
              </Grid>
            </Hidden>

            {termsAndPrivacyLinks && (
              <ListLink>
                {_.map(termsAndPrivacyLinks, (link, index) => (
                  <TermItem key={index}>
                    <FooterLinks>
                      <Link href={link.url} passHref>
                        <TermsLink>{link.title}</TermsLink>
                      </Link>
                      {index + 1 < _.size(termsAndPrivacyLinks) ? <Pipe> | </Pipe> : null}
                    </FooterLinks>
                  </TermItem>
                ))}
              </ListLink>
            )}
          </Grid>
        </Container>
      </ContentWrapper>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  termsAndPrivacyLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      openInNewWindow: PropTypes.bool,
    })
  ),
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      iconName: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  pageLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      openInNewWindow: PropTypes.bool,
    })
  ),
};

Footer.defaultProps = {
  termsAndPrivacyLinks: null,
  socialLinks: null,
  pageLinks: null,
};

export default Footer;
