import styled from 'styled-components';

import { StyledLink } from '../nav-links';
import { ExtraSmall, H4 } from '../type';
import { BREAKPOINTS, SPACE, GLOBAL, COLORS } from '../../constants';

const StyledHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: calc(${SPACE.X1} + ${SPACE.X0});

  @media (min-width: ${BREAKPOINTS.SM}px) {
    padding-bottom: ${SPACE.X1};
  }
`;

const StyledH4 = styled(H4)`
  font-weight: normal;
  font-family: ${GLOBAL.BRAND_FONT_FAMILY_SECOND};
  margin-bottom: 96px;
`;

const StyledSmallTitle = styled(ExtraSmall)`
  margin-bottom: ${SPACE.X0};
`;

const StyledVehicleLinks = styled.div`
  align-items: center;
  border-top: 1px solid ${COLORS.GREY_FIVE};
  display: flex;
  justify-content: space-between;
  padding: ${SPACE.X1} 0;
`;

// Ensure the multi-line links do not have uneven focus outline with min-height rule.
const StyledNavLink = styled(StyledLink)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 2rem;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.LG}px) {
    padding: 0;
  }
`;

const StyledBody = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: ${SPACE.X2};

  @media (min-width: ${BREAKPOINTS.SM}px) {
    padding: calc(${SPACE.X1} + ${SPACE.X0}) calc(${SPACE.X2} + ${SPACE.X0}) ${SPACE.X0};
  }
`;

const StyledFooter = styled.div`
  box-shadow: 0 2px 11px 3px rgb(68, 68, 68, 0.1);
  margin-top: auto;
  padding: ${SPACE.X2};

  @media (min-width: ${BREAKPOINTS.SM}px) {
    padding: calc(${SPACE.X1} + ${SPACE.X0}) calc(${SPACE.X2} + ${SPACE.X0});
  }
`;

const AlertWrapper = styled.div`
  padding-bottom: ${SPACE.X1};
`;

const StyledMiniCart = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  margin-bottom: ${SPACE.X1};
`;

const StyledNavLinkText = styled.div`
  margin-right: ${SPACE.X1};
`;

export {
  StyledMiniCart,
  StyledHeader,
  StyledBody,
  StyledH4,
  StyledSmallTitle,
  StyledVehicleLinks,
  StyledNavLink,
  StyledNavLinkText,
  AlertWrapper,
  StyledFooter,
};
