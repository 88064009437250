import React from 'react';
import styled from 'styled-components';
import MuiContainer from '@material-ui/core/Container';
import { spacing } from '@material-ui/system';

import { GRID } from '../../constants';

const StyledMuiContainer = styled(MuiContainer)`
  ${spacing}
`;

const Container = ({ className, maxWidth, children, ...props }) => {
  const containerMaxWidth = maxWidth || GRID.CONTAINER_MAX_WIDTH;

  return (
    <StyledMuiContainer className={className} maxWidth={containerMaxWidth} {...props}>
      {children}
    </StyledMuiContainer>
  );
};

export default Container;
