import { get } from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { PrimaryButton } from '../button';
import RichText from '../rich-text';
import { H3, SectionHeadingStyles } from '../type';
import { BREAKPOINTS, SPACE } from '../../constants';
import { useGutterSpacing } from '../../hooks/use-gutter-spacing';
import { ContentBlockWrapper } from '../content-block-wrapper';

const Image = ({ className, src, alt }) => {
  return <img {...{ className }} alt={alt} src={src} width="100%" loading="lazy" />;
};

const Video = ({ captions, src, mimeType, poster }) => (
  <video crossOrigin="anonymous" autoPlay loop muted playsInline poster={poster} width="100%" controls>
    <track label="English" kind="captions" srcLang="en" src={get(captions, '[0].url')} default />
    <source src={src} type={mimeType} />
  </video>
);

const StyledH3 = styled(H3)`
  margin-bottom: ${SPACE.X1};
`;

const StyledRichText = styled(RichText)`
  ${SectionHeadingStyles};
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  &.MuiButton-root {
    margin-top: ${SPACE.X2};
  }
`;

const StyledBox = styled(Box)`
  width: 100%;
  @media (min-width: ${BREAKPOINTS.LG}px) {
    width: unset;
  }
`;

const ContentMediaBlock = ({ useBackgroundImage, children, media, heading, body, callToAction, isReverse }) => {
  const lgUp = useMediaQuery(`(min-width: ${BREAKPOINTS.LG}px)`);
  const theme = useTheme();

  const useStyles = makeStyles({
    // Remove MUI vertical gutter spacing
    root: {
      justifyContent: (props) => (props.isReverse ? 'flex-end' : 'flex-start'),
      '& > .MuiGrid-item': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    [theme.breakpoints.down('lg')]: {
      image: {
        order: (props) => (props.isReverse && props.useBackgroundImage ? 2 : 1),
      },
      content: {
        order: (props) => (props.isReverse && props.useBackgroundImage ? 1 : 2),
      },
    },
    [theme.breakpoints.up('lg')]: {
      image: {
        order: (props) => (props.isReverse ? 2 : 1),
      },
      content: {
        order: (props) => (props.isReverse ? 1 : 2),
      },
    },
  });

  const gridStyle = useStyles({ useBackgroundImage, isReverse });

  return (
    <ContentBlockWrapper url={media.kind === 'image' ? media.src : null} useBackgroundImage={useBackgroundImage}>
      <Container maxWidth="xl">
        <Box pt={lgUp ? SPACE.X3 : SPACE.X2} pb={lgUp ? SPACE.X3 : '48px'}>
          <Grid container spacing={useGutterSpacing()} classes={{ root: gridStyle.root }}>
            <Grid item xs={useBackgroundImage ? 6 : 12} lg={6} classes={{ root: gridStyle.image }}>
              {!useBackgroundImage && (
                <Box mb={lgUp ? 0 : SPACE.X2}>
                  {media.kind === 'image' ? <Image {...media} /> : <Video {...media} />}
                </Box>
              )}
            </Grid>
            <Grid
              item
              container
              direction="column"
              justify="center"
              alignItems="center"
              xs={useBackgroundImage ? 6 : 12}
              lg={5}
              classes={{ root: gridStyle.content }}
            >
              <StyledBox
                justifyContent="flex-start"
                ml={!isReverse && lgUp ? SPACE.X2 : 0}
                mr={isReverse && lgUp ? SPACE.X2 : 0}
              >
                {heading && <StyledH3 as="h2">{heading}</StyledH3>}
                {body && <StyledRichText {...{ callToAction }}>{body}</StyledRichText>}
                {children}
              </StyledBox>
              <StyledBox ml={!isReverse && lgUp ? SPACE.X2 : 0} mr={isReverse && lgUp ? 'SPACE.X2' : 'auto'}>
                {callToAction && (
                  <StyledPrimaryButton fullWidth={!lgUp} href={callToAction.href}>
                    {callToAction.label}
                  </StyledPrimaryButton>
                )}
              </StyledBox>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ContentBlockWrapper>
  );
};

ContentMediaBlock.query = `
  ... on contentMatrix_contentMediaBlock_BlockType {
    id
    typeHandle
    heading
    body
    useBackgroundImage
    isReverse
    ctaButton {
      ...on ctaButton_BlockType {
        pdfDocument {
          url
        }
        isEnabled
        label
        externalUrl
        internalEntry {
          uri
          slug
        }
      }
    }
    media {
      kind
      mimeType
      title
      url
      ...on contentAssets_Asset {
        posterImage {
          url
          title
        }
        captions {
          url
        }
      }
    }
  }
  `;

export default ContentMediaBlock;

ContentMediaBlock.propTypes = {
  useBackgroundImage: PropTypes.bool,
  children: PropTypes.node,
  media: PropTypes.shape({
    kind: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  heading: PropTypes.string,
  body: PropTypes.string,
  callToAction: PropTypes.shape({
    label: PropTypes.string,
    href: PropTypes.string,
    to: PropTypes.string,
  }),
  isReverse: PropTypes.bool,
};

ContentMediaBlock.defaultProps = {
  useBackgroundImage: false,
  children: null,
  heading: null,
  body: null,
  media: {
    kind: 'image',
    src: '/images/content-media-block-placeholder.jpg',
    alt: 'content image block - default image',
  },
  callToAction: null,
  isReverse: false,
};
