import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CompareCarIcon from '../icons/compare-car-icon';
import { CompareButton } from '../button';
import { BREAKPOINTS } from '../../constants';

const StyledCompareLabel = styled.span`
  margin-right: 0.25rem;
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  overflow: hidden;
  white-space: nowrap;

  @media (min-width: ${BREAKPOINTS.MD}px) {
    display: inline;
    position: static !important;
    clip: auto;
    width: auto !important;
    height: auto !important;
    padding: auto !important;
    overflow: visible;
    white-space: normal;
  }
`;

const StyledCompareButton = styled(CompareButton)`
  @media (max-width: ${BREAKPOINTS.SM}px) {
    &.MuiButton-root {
      padding: 6px;
    }
    && .MuiButton-startIcon {
      margin-right: 4px;
    }
  }
`;

const CompareCars = ({ compareButtonProps }) => {
  const { count, onOpenComparison } = compareButtonProps;

  return (
    <StyledCompareButton
      {...{ count }}
      className="tertiary"
      startIcon={<CompareCarIcon aria-hidden />}
      onClick={() => onOpenComparison()}
    >
      <StyledCompareLabel>Compare: </StyledCompareLabel>
      {count}
    </StyledCompareButton>
  );
};

export default CompareCars;

CompareCars.propTypes = {
  compareButtonProps: PropTypes.shape({
    count: PropTypes.number.isRequired,
    onOpenComparison: PropTypes.func.isRequired,
  }).isRequired,
};
