import * as Yup from 'yup';

// UK Government Data Standards Catalogue suggests 35 characters for each of Given Name and Family Name, or 70 characters for a single field to hold the Full Name. https://webarchive.nationalarchives.gov.uk/+/http://www.cabinetoffice.gov.uk/govtalk/schemasstandards/e-gif/datastandards.aspx

const formValidation = {
  requiredFirstName: Yup.string()
    .max(35, 'First name cannot be more than 35 characters')
    .required('Please enter your first name'),
  requiredLastName: Yup.string()
    .max(35, 'Last name cannot be more than 35 characters')
    .required('Please enter your last name'),
  requiredEmail: Yup.string().email('Please enter a valid email').required('Please enter an email'),
  phone: Yup.string()
    .max(35, 'Phone number cannot be more than 35 characters')
    .min(10, 'Please enter a valid phone number including area code'),
  requiredMessage: Yup.string()
    .max(1500, 'Message can be no longer than 1500 characters')
    .required('Please enter a message'),
  requiredState: Yup.string().required('Please select a state'),
};

export default formValidation;
