import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import MuiFormControl from '@material-ui/core/FormControl';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import MuiInputLabel from '@material-ui/core/InputLabel';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Icon, { iconNames } from '../icons';
import { BREAKPOINTS, BOX_SHADOW, COLORS } from '../../constants';

import { fieldStyles, formTheme } from './field-styles';

const StyledIcon = styled(Icon)`
  fill: ${formTheme.palette.secondary.main};
  height: auto;
  pointer-events: none;
  position: absolute;
  right: 18px;
  top: calc(50% - 5px);
  width: 12px;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    top: calc(50% - 8px);
    width: 18px;
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    top: calc(50% - 10px);
    width: 20px;
  }
`;

const Wrapper = styled.div`
  .MuiInputBase-root {
    ${fieldStyles.root}
  }

  .MuiOutlinedInput-inputSelect {
    padding-right: 46px;
    color: ${formTheme.palette.secondary.light};
  }

  .MuiInputLabel-outlined {
    color: ${formTheme.palette.secondary.main};
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${formTheme.palette.secondary.main};
  }

  .MuiOutlinedInput-root:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${formTheme.palette.secondary.light};
    }

    ${StyledIcon} {
      fill: ${formTheme.palette.secondary.light};
    }
  }

  .MuiOutlinedInput-root.Mui-focused.Mui-focused {
    ${StyledIcon} {
      fill: ${formTheme.palette.primary.main};
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${formTheme.palette.primary.main};
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${formTheme.palette.primary.main};
    }
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${formTheme.palette.primary.main};
  }

  .MuiFormHelperText-root {
    ${fieldStyles.helperText}
    color: ${formTheme.palette.secondary.main};
  }
`;

const StyledFormControl = styled(MuiFormControl)`
  ${fieldStyles.label}
  ${fieldStyles.outline}

  .MuiFormLabel-root {
    ${fieldStyles.root}
  }

  width: 100%;
`;

const StyledMenuItem = styled(MuiMenuItem)`
  font-size: ${formTheme.typography.menu.fontSize} !important;
`;

const Select = (props) => {
  const [value, setValue] = React.useState('');
  return <ControlledSelect value={value} onChange={setValue} {...props} />;
};

const ControlledSelect = ({ children, value, id, onChange, label, options, error, helperText }) => {
  const useStyles = makeStyles({
    // Inject styles for options menu which is not a child of any styled-components.
    paper: {
      border: `1px solid ${COLORS.BRAND_GREY_SEVEN}`,
      borderRadius: 0,
      boxShadow: BOX_SHADOW.DEFAULT,
    },
  });
  const popoverStyle = useStyles();

  const inputLabelRef = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabelRef?.current?.offsetWidth * 1.2);
  }, []);

  if (_.isEmpty(options) && _.isNil(children)) {
    return null;
  }

  const transformedLabel = _.kebabCase(label);

  return (
    <Wrapper>
      <StyledFormControl variant="outlined" error={error}>
        <MuiInputLabel id={_.kebabCase(label)} ref={inputLabelRef} htmlFor={transformedLabel}>
          {label}
        </MuiInputLabel>
        <MuiSelect
          value={value}
          onChange={(event) => onChange(event.target.value)}
          input={<OutlinedInput labelWidth={labelWidth} name={transformedLabel} id={id || transformedLabel} />}
          IconComponent={() => <StyledIcon name={iconNames.caret} />}
          labelId={_.kebabCase(label)}
          MenuProps={{
            elevation: 0,
            PopoverClasses: {
              paper: popoverStyle.paper,
            },
          }}
        >
          {/* Default to children prop and then to options */}
          {children ||
            _.map(options, (option) => (
              <StyledMenuItem key={option.value} value={option.value}>
                {option.label}
              </StyledMenuItem>
            ))}
        </MuiSelect>
        {helperText && <MuiFormHelperText>{helperText}</MuiFormHelperText>}
      </StyledFormControl>
    </Wrapper>
  );
};

ControlledSelect.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    })
  ),
  error: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
};

ControlledSelect.defaultProps = {
  children: null,
  id: null,
  label: null,
  error: false,
  options: null,
  helperText: null,
};

ControlledSelect.MenuItem = StyledMenuItem;

export default Select;

export { ControlledSelect };
