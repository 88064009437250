import axios from 'axios';
import Router from 'next/router';
import _ from 'lodash';
// @NOTE: graphql-query-compress imported like this for SSR https://github.com/rse/graphql-query-compress/issues/10
import graphQlQueryCompress from 'graphql-query-compress/lib/graphql-query-compress.browser.js';

import ContentMatrix from '../components/content-matrix';
import PageHeaderMatrix from '../components/page-header-matrix';
import { SECTION_HANDLES } from '../constants';

import { CustomError } from './errors';
import { GRAPHQL_IMAGE_FIELDS } from './get-vehicle-image';
import constructSlug from './construct-slug';
import logger from './logger';

const compressQuery = (query) => _.trim(graphQlQueryCompress(query));

const getCmsUrl = () => process.env.NEXT_PUBLIC_CMS_URL;

const fetchFromGqlPost = (options = {}, config = {}) => {
  const { query: _query, variables } = options;

  if (_.isNil(_query)) {
    throw new Error('`query` option is required when calling fetchFromGqlPost.');
  }

  const query = compressQuery(_query);

  const data = {
    query,
    variables,
  };

  return axios.post(`${getCmsUrl()}/api`, data, config);
};

/*
  Note: variables not supported in Craft GQL GET queries:
  https://docs.craftcms.com/v3/graphql.html#sending-api-requests
  "If you need to specify any variables along with your query, then you must send request as a POST request"

  A workaround for this is using the query itself, like so:
  {entries(title:"Test Page"){title, id}}
  */

const fetchFromGqlGet = (options = {}, config = {}) => {
  const cmsUrl = getCmsUrl();

  const { query: _query, variables } = options;

  if (_.isNil(_query)) {
    throw new Error('`query` option is required when calling fetchFromGqlGet.');
  }

  if (!_.isEmpty(variables)) {
    throw new Error('`variables` cannot be passed when using a GET request. Please use POST instead.');
  }

  const query = compressQuery(_query);

  return axios.get(`${cmsUrl}/api?query=${query}`, config);
};

/**
 * Fetches data from the GraphQL API
 * @param object options
 * @param object options.method - HTTP method to use ('get' or 'post')
 * @param string options.query - GraphQL query
 * @param string options.operationName - Name of the operation which can be used for GQL analytics
 * @param object options.variables - Variables to replace placeholders in the query
 * @returns promise
 */
const fetch = (options = { method: 'get' }, config) => {
  const passThruOptions = _.omit(options, ['method']);
  return options.method === 'post'
    ? fetchFromGqlPost(passThruOptions, config)
    : fetchFromGqlGet(passThruOptions, config);
};

const fetchEntries = async (query) => {
  const response = await fetch({ query });
  if (response.data.errors) {
    throw new CustomError({
      errors: response.data.errors,
    });
  }
  return response;
};

const buildContentMatrixQuery = ({ section, slug }, withPageHeader = true, withContentMatrix = true) => `
  {
    entries(section: "${section}", slug: "${slug}") {
      ... on ${section}_${section}_Entry {
        ${withContentMatrix ? ContentMatrix.query : ''}
        ${withPageHeader ? PageHeaderMatrix.query : ''}
      }
    }
  }`;

const fetchSafetyFeatureCategories = async () => {
  const safetyFeatureCategoriesQuery = `{
    categories {
      ...on safetyFeatureCategories_Category {
        id
        title
        slug
        categoryDescription
        tacSafetyFeatureEntries {
          id
          title
          slug
          uri
          ...on tacSafetyFeatures_tacSafetyFeatures_Entry {
            featureIcon {
              url
            }
            isRecommended
            isPrimaryFeature
            tacFeatureCode
            featureDescription
            redbookSafetyFeatureEntries {
              id
              title
              redbookFeatureCode
            }
          }
        }
      }
    }
  }
  `;

  const safetyFeatureCategoriesResponse = await fetch({ query: safetyFeatureCategoriesQuery });
  return _.filter(safetyFeatureCategoriesResponse.data.data.categories, (category) => !_.isEmpty(category));
};

const fetchAncapTestCategories = async () => {
  const ancapTestCategoriesQuery = `{
    categories {
      ...on testCategories_Category {
        id
        title
        slug
        categoryDescription
        tests {
          id
          title
        }
      }
    }
  }`;
  const ancapTestCategoriesQueryResponse = await fetch({ query: ancapTestCategoriesQuery });
  const ancapTestCategories = _.filter(
    ancapTestCategoriesQueryResponse.data.data.categories,
    (category) => !_.isEmpty(category)
  );

  return ancapTestCategories;
};

const modelsQuery = (make) => `{
  entries(section: "${SECTION_HANDLES.VEHICLES}", makeId:"${make}") {
    ...on vehicles_vehicle_Entry {
      makeDescription
      modelId
      modelDescription
      redbookImage
    }
  }
}`;

const fetchModels = async (make) => {
  try {
    const response = await fetch({ query: modelsQuery(make) });
    if (response.data.errors) {
      logger.error('Query error when fetching models for make', { make, errors: response.data.errors });
    }

    const models = _.get(response, 'data.data.entries', []);
    const modelValues = _.filter(
      _.uniqBy(models, ({ modelDescription }) => modelDescription),
      (value) => !_.isEmpty(value)
    );

    return { models: modelValues };
  } catch (error) {
    logger.error('Error when fetching models for make', error, { make });
    return { error: { status: 500 } };
  }
};

const getDidYouKnowTips = async () => {
  const didYouKnowQuery = `{
        entries(type: "didYouKnowTips") {
          ...on didYouKnowTips_didYouKnowTips_Entry {
            body: body
          }
        }
      }`;

  const didYouKnowResponse = await fetch({ query: didYouKnowQuery });

  return _.get(didYouKnowResponse, 'data.data.entries', []);
};

const yearsQuery = (make, model) => `{
  entries(section: "${SECTION_HANDLES.VEHICLES}", makeId:"${make}", modelId:"${model}") {
    ...on vehicles_vehicle_Entry {
      year
    }
  }
}`;

const pageVehiclesQuery = (make, model, year) => `{
  entries(section: "${SECTION_HANDLES.VEHICLES}", makeId:"${make}", modelId:"${model}", year:"${year}") {
      ...on vehicles_vehicle_Entry {
        ancapRating
        badgeDescription
        derivedBodyStyle
        engineDisplacement
        id
        isBaseOption
        isBlacklisted
        makeDescription
        modelDescription
        newPrice
        pPlateStatus
        privateMaxPrice
        privateMinPrice
        ratingProviderOverride
        redbookCode
        segment
        sequenceNumber
        series
        slug
        transmission
        ucsrAggressivity
        ucsrDriver
        ucsrPrimarySafety
        ucsrRating
        ucsrSaferPick
        vehicleDescription
        year
        ${GRAPHQL_IMAGE_FIELDS}
    }
  }
}`;

// **BUSINESS LOGIC**
// Check a vehicle's UCSR rating and if it is less than 5, return a new vehicle object with a ucsrSaferPick of false
const checkUcsrSaferPick = (vehicle) => {
  const { ucsrRating } = vehicle;
  const ucsrSaferPick = ucsrRating < 5 ? false : vehicle.ucsrSaferPick ?? false;
  return { ...vehicle, ucsrSaferPick };
};

const buildVehicleSafetyFeaturesQuery = (pageVehicle) => {
  return `
    {
      entries(section: "${SECTION_HANDLES.VEHICLES}", id:${pageVehicle.id}) {
        ...on vehicles_vehicle_Entry {
          safetyFeatureAvailability {
            ...on safetyFeatureAvailability_BlockType {
              safetyFeature {
                id
                title
              }
              availability
            }
          }
        }
      }
    }
    `;
};

const buildAncapTestRecordQuery = (pageVehicle) => `
{
  entries(section: "${SECTION_HANDLES.ANCAP_TEST_RECORDS}", relatedTo:${_.toInteger(pageVehicle.id)}) {
    id
    ...on ancapTestRecords_ancapTestRecords_Entry {
      id
      title
      slug
      rating
      ratingYear
      technicalReportUrl
      testResults {
        ...on testResults_BlockType {
          id
          test {
            ...on ancapTests_ancapTests_Entry {
              id
              title
            }
          }
          testType
          testValue
          maxValue
          units
        }
      }
    }
  }
}
`;

/**
 * Navigates to a vehicle page at whichever level is possible, based on the information passed.
 *
 * @param {string} make Slug of the vehicle make
 * @param {string} model Slug of the vehicle model
 * @param {string} year Year of the vehicle’s manufacture
 * @param {string} vehicle Slug of the specific vehicle variant
 * @param {boolean} shouldReplace Boolean indicating if the router should prevent adding an entry into the history stack
 */
const navigateToVehiclePage = async (make, model, year, vehicle, shouldReplace = false) => {
  let url = `/[make]`;
  let asPath = `/${constructSlug(make)}`;

  if (!_.isUndefined(model)) {
    url = `${url}/[model]`;
    asPath = `${asPath}/${constructSlug(model)}`;
  }

  if (!_.isUndefined(year)) {
    url = `${url}/[year]`;
    asPath = `${asPath}/${year}`;
  }

  if (!_.isUndefined(vehicle)) {
    url = {
      pathname: url,
      query: { vehicle },
    };
    asPath = `${asPath}?vehicle=${vehicle}`;
  }

  Router[shouldReplace ? 'replace' : 'push'](url, asPath);
};

export {
  buildContentMatrixQuery,
  fetch,
  fetchEntries,
  fetchFromGqlGet,
  fetchFromGqlPost,
  fetchModels,
  fetchAncapTestCategories,
  getCmsUrl,
  getDidYouKnowTips,
  fetchSafetyFeatureCategories,
  navigateToVehiclePage,
  checkUcsrSaferPick
};
